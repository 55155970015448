import { GlobalService } from './global.service'
import { Inject, Injectable, Injector } from '@angular/core'
import { THEME } from './theme'

@Injectable({ providedIn: 'root' })
export class ThemeService {

  constructor(
    public injector: Injector,
    public g: GlobalService,
  ) { 
    console.info(`## ${this.constructor.name}`) 
  }

  clone(nick?) {
    const _nick = nick || this.g.nick
  }

  changeTheme(program) {
    if (program && typeof document !== "undefined") {
      const head = (document as any).querySelector('head')
      const style = (document as any).createElement('style')
      style.id = 'css-program'
      let programVars = ':root {'

      // const element = document.documentElement;

      for (const j of ['layout', 'responsiveness', 'typography']) {
        if (program[j]) {
          for (const property in program[j]) {
            if (program[j][property]) {
              // element.style.setProperty(`--${property}`, program[j][property]);
              programVars += `--${property}: ${program[j][property]};`
            }
          }
        } else { program[j] = THEME[j] }
      }

      if (program.colors && Object.keys(program.colors).length) {
        for (const color in program.colors) {
          programVars += `--${color}: ${program.colors[color]} !important;`
        }
      } else {
        if (program.colors3) {
          for (const color of program.colors3) {
            // element.style.setProperty(`--${color.name}`, color.value)
            programVars += `--${color.name}: ${color.value} !important;`
          }
        } else program.colors3 = THEME.colors3
      }

      for (const color of THEME.colorsYES) {
        // element.style.setProperty(`--${color.name}`, color.value)
        programVars += `--${color.name}: ${color.value} !important;`
      }

      if (program.colorVariants && Object.keys(program.colorVariants).length) {
        for (const color in program.colorVariants) {
          programVars += `--${color}: ${program.colorVariants[color]} !important;`
        }
      } else {
        // fallback para programas que ainda não possuem as colorVariants
        for (const color in program.colors) {
          if (color.indexOf('Hover') > -1) {
            programVars += `--${color.replace('Hover', '')}-700: ${program.colors[color]} !important;`
          }
        }
      }

      if (program.images && program.images.desktopBg) programVars += `--desktopBg: ${program.images.desktopBg};`
      if (program.images && program.images.mobileBg) programVars += `--mobileBg: ${program.images.mobileBg};`

      style.innerText += programVars + '}'
      head.appendChild(style)
    }

    return program
  }

}
