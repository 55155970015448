import { GlobalService } from '../core/global.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { isDevMode } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SubSink } from "subsink";
import { DOCUMENT } from '@angular/common';
import { FirestoreService } from "../core/firestore.service";
import { Router } from '@angular/router';

@Component({
  selector: 'site-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.sass']
})

export class NavSiteComponent implements OnInit, OnDestroy {
  isDev;
  pages: any[] = null
  private subs = new SubSink();

  constructor(
    public deviceService: DeviceDetectorService,
    public g: GlobalService,
    private fss: FirestoreService,
    private router: Router,
    @Inject(DOCUMENT) private readonly document: any
  ) {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    // console.log('NavSiteComponent init')
    this.isDev = isDevMode()
    this.getPages()
  }

  getPages() {
    this.subs.add(
      this.fss.list('pages', {
        where: [['active', '==', true], ['showInNav', '==', true]],
        api: true,
        orderBy: 'order'
      }).subscribe(pages => {
        if (pages && pages.length) {
          // console.log(pages)
          this.pages = pages
        }
      })
    )
  }

  goToPage(page) {
    if (!page.useTemplate) this.router.navigate([page.path])
    else this.router.navigate([`/block-page/${page.id}`])
  }
}
